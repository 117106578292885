<template>
  <div>
    <i @click="cameraDialog = true; loadingCamera = false" class="mt-3 mr-3 fa-3x fa-light fa-qrcode"></i>

    <v-dialog v-model="cameraDialog" max-width="500">
      <v-card class="text-center">
        <v-card-title>
          <p class="h3">
            Scan een qrcode
          </p>
          <v-spacer/>
          <v-icon aria-label="Close" @click="cameraDialog = false; camera = 'off'">
            mdi-close
          </v-icon>
        </v-card-title>


        <v-progress-circular
          v-if="loadingCamera"
          indeterminate
          color="primary"
          class="mt-10 mb-10"
        ></v-progress-circular>
        <div v-else >
          <qrcode-stream class="px-5" :camera="camera" @decode="onDecode" @init="onInit"/>
          <v-btn class="mt-4" color="blue" @click="switchCamera">
            <i class="fa-regular fa-camera-rotate white--text fa-2x"></i>
          </v-btn>
        </div>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="blue" text @click="cameraDialog = false; camera = 'off'">
            Annuleren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <material-snackbar
      v-model="snackbar.visible"
      :type="snackbar.type"
      timeout="-1"
      v-bind="{
        [snackbar.direction[0]]: true,
        [snackbar.direction[1]]: true}"
    >{{ snackbar.text }}
    </material-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'DefaultQrCode',
    component: {},
    data: () => {
      return {
        qrCode: [
          {
            title: 'Camera openen',
            icon: 'fa-regular fa-camera',
          },
          {
            title: 'Van fotos',
            icon: 'fa-regular fa-images',
          },
        ],
        snackbar: {
          visible: false,
          type: 'error',
          direction: ['top', 'center'],
          text: '',
        },
        cameraDialog: false,
        camera: 'rear',
        result: null,
        showScanConfirmation: false,
        loadingCamera: false,
      }
    },
    mounted () {
      this.camera = 'rear';
    },
    watch: {
      cameraDialog: function (value) {
        if (value) {
          this.camera = 'on'
        } else {
          this.camera = 'off'
        }
      },
    },
    methods: {
      switchCamera () {
        switch (this.camera) {
          case 'front':
            this.camera = 'rear'
            break
          case 'rear':
            this.camera = 'front'
            break
          default:
            this.camera = 'rear'
        }
      },
      async onInit (promise) {
        try {
          await promise
        } catch (err) {
          this.snackbar.visible = true
          this.snackbar.type = 'error'
          this.snackbar.text = `Er is iets mis gegaan: ${err}. Neem contact met beheerders.`
        }
      },

      async onDecode (id) {
        this.loadingCamera = true
        this.$auth.getSomething(id).then(res => {
          if (!res.length) {
            this.snackbar.visible = true
            this.snackbar.type = 'warning'
            this.snackbar.text = `Deze qrcode blijkt ongeldig te zijn. Probeer het nogmaals`
            return
          }
          const product = res[0]
          if(product?.validForDays) {
            this.$router.push(`/subscriptions/${product.id}`)
          }else if (product?.timeBlockStatus) {
            this.$router.push(`/orders/${product.groupId}`)
          }
          this.cameraDialog = false
          this.camera = 'off'
          this.loadingCamera = false
        }).catch((err) => {
          this.snackbar.visible = true
          this.snackbar.type = 'error'
          this.snackbar.text = `Er is iets mis gegaan: ${err}. Neem contact met beheerders.`
          this.cameraDialog = false
          this.camera = 'off'
          this.loadingCamera = false
        }).finally(() => {
          this.showScanConfirmation = true
        })
      },
    },
  }
</script>
